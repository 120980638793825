import { clsx } from 'clsx';
import { filter } from 'graphql-anywhere';
import dynamic from 'next/dynamic';

import type { BalloonProps } from './type';

import { CreatorPostFormModal } from '@/components/features/creatorPost/CreatorPostFormModal';
import { CreatorPostLikedCreatorsModal } from '@/components/features/creatorPost/CreatorPostLikedCreatorsModal';
import { IconDefaultProfile, IconDots } from '@/components/icons';
import {
  Image,
  AnchorLink,
  DropDown,
  TextEllipsis,
  HeadlessButton,
  BaseModal,
  Button,
} from '@/components/ui';
import { ROUTE, DATE_FORMAT, CONTENT_WIDTH } from '@/constants';
import { CreatorPostFormModalCreatorPostsFragmentDoc } from '@/graphql/generated';
import { CreatorPostLikeButtonCreatorPostsFragmentDoc } from '@/graphql/generated.creator';
import { CreatorPostCardDeleteCreatorPostDocument } from '@/graphql/generated.creator-anonymous';
import { useDisclosure } from '@/hooks/utils';
import { useMutationWrapper } from '@/libs/apollo';
import { formatDate } from '@/utils/date';
import { formatCountNumber } from '@/utils/ui';

import { TEXT_MAX_LINES } from '../const';

export const Balloon = ({
  creatorPost,
  parsedText,
  isSelf,
  isEditable,
  readOnly,
  onSuccessUpdateLike,
  onSuccessEdit,
  onSuccessDelete,
}: BalloonProps) => {
  const editModal = useDisclosure();
  const deleteAlertModal = useDisclosure();
  const likeCreatorModal = useDisclosure();

  const CreatorPostLikeButton = dynamic(() =>
    import('@/components/features/creatorPost').then(
      (module) => module.CreatorPostLikeButton
    )
  );

  const [deleteCreatorPostMutation, deleteCreatorPostMutationState] =
    useMutationWrapper(CreatorPostCardDeleteCreatorPostDocument);

  const handleDeletePost = () => {
    deleteCreatorPostMutation({
      variables: {
        creatorPostId: creatorPost.id,
      },
      onCompleted: ({ deleteCreatorPost }) => {
        onSuccessDelete(deleteCreatorPost.postId);
        deleteAlertModal.close();
      },
    });
  };

  return (
    <>
      {creatorPost.creator && (
        <article className={clsx('tw-flex tw-gap-2')}>
          <div
            className={clsx(
              'tw-w-8 tw-h-8 md:tw-w-[38px] md:tw-h-[38px]',
              'tw-rounded-circle tw-overflow-hidden'
            )}
          >
            <AnchorLink href={ROUTE.profile(creatorPost.creator.id)}>
              {creatorPost.creator.profileImage?.url ? (
                <Image
                  src={creatorPost.creator.profileImage?.url}
                  alt=""
                  objectFit="cover"
                  size={{ sp: '32px', tablet: '38px', pc: '38px' }}
                />
              ) : (
                <IconDefaultProfile boxSize="40px" />
              )}
            </AnchorLink>
          </div>
          <div className={clsx('tw-flex-1 tw-space-y-2')}>
            <div
              className={clsx(
                'tw-p-4 tw-rounded-lg tw-rounded-tl-none tw-bg-gray-100'
              )}
            >
              <header className={clsx('tw-full')}>
                <div className={clsx('tw-space-y-1')}>
                  <div
                    className={clsx(
                      'tw-flex tw-items-center tw-justify-between'
                    )}
                  >
                    <span
                      className={clsx(
                        'tw-text-ui14 tw-font-bold tw-line-clamp-1'
                      )}
                    >
                      {creatorPost.creator.nickname}
                    </span>

                    {isSelf && (
                      <DropDown trigger={<IconDots />} isDisable={!isEditable}>
                        <ul>
                          <li
                            className={clsx(
                              'tw-pt-2 tw-pb-3 tw-px-4',
                              'tw-border-b tw-border-gray-200'
                            )}
                          >
                            <button
                              type="button"
                              className={clsx(
                                'tw-text-ui16',
                                'hover:tw-opacity-75 tw-transition tw-duration-200'
                              )}
                              onClick={editModal.open}
                            >
                              返信を編集
                            </button>
                          </li>
                          <li className={clsx('tw-pt-2 tw-pb-3 tw-px-4')}>
                            <button
                              type="button"
                              className={clsx(
                                'tw-text-ui16 tw-text-error',
                                'hover:tw-opacity-75 tw-transition tw-duration-200'
                              )}
                              onClick={deleteAlertModal.open}
                            >
                              返信を削除
                            </button>
                          </li>
                        </ul>
                      </DropDown>
                    )}
                  </div>

                  <time
                    dateTime={creatorPost.publishedAt}
                    className={clsx('tw-text-ui10 tw-text-gray-600')}
                  >
                    {formatDate(
                      creatorPost.publishedAt,
                      DATE_FORMAT.yyyyMMddHHmm
                    )}
                  </time>
                </div>
              </header>
              {parsedText != null && (
                <section className={clsx('tw-mt-2')}>
                  <TextEllipsis
                    text={parsedText}
                    maxLine={TEXT_MAX_LINES}
                    ellipsisText="..."
                    className={clsx(
                      'tw-text-sns14 tw-whitespace-break-spaces tw-break-all'
                    )}
                    footer={
                      <button
                        className={clsx(
                          'tw-w-full tw-pt-3.5 tw-text-sns14 tw-text-gray-700 tw-text-left',
                          'group-hover:tw-opacity-75 tw-transition tw-duration-200'
                        )}
                      >
                        続きを読む
                      </button>
                    }
                  />
                </section>
              )}
              {creatorPost.image != null && (
                <section className={clsx('tw-mt-4')}>
                  <a
                    href={creatorPost.image.url}
                    target="_blank"
                    rel="noreferrer"
                    className={clsx(
                      'tw-block tw-w-fit tw-max-w-full tw-max-h-48 tw-rounded-lg tw-overflow-hidden',
                      'md:tw-max-h-64'
                    )}
                  >
                    <Image
                      src={creatorPost.image.url}
                      alt=""
                      size={{
                        sp: `${CONTENT_WIDTH.sp}px`,
                        tablet: `${CONTENT_WIDTH.tablet / 2}px`,
                        pc: `${CONTENT_WIDTH.pc / 2}px`,
                      }}
                    />
                  </a>
                </section>
              )}
            </div>

            {!readOnly && (
              <section
                className={clsx('tw-flex tw-items-center tw-justify-between')}
              >
                <HeadlessButton onClick={likeCreatorModal.open}>
                  <span className={clsx('tw-text-ui12 tw-text-gray-600')}>
                    {formatCountNumber(creatorPost.likeTotal, 0, 9999)}
                    件のいいね
                  </span>
                </HeadlessButton>
                <CreatorPostLikedCreatorsModal
                  isOpen={likeCreatorModal.isOpen}
                  postId={creatorPost.id}
                  onClose={likeCreatorModal.close}
                />

                <CreatorPostLikeButton
                  creatorPost={filter(
                    CreatorPostLikeButtonCreatorPostsFragmentDoc,
                    creatorPost
                  )}
                  onSuccessUpdateLike={onSuccessUpdateLike}
                />
              </section>
            )}

            <CreatorPostFormModal
              isOpen={editModal.isOpen}
              creatorPost={filter(
                CreatorPostFormModalCreatorPostsFragmentDoc,
                creatorPost
              )}
              onSuccessUpsert={onSuccessEdit}
              onClose={editModal.close}
            />
            <BaseModal
              isOpen={deleteAlertModal.isOpen}
              title="返信を削除しますか？"
              ariaLabel="返信削除警告"
              closeOnOverlayClick={!deleteCreatorPostMutationState.loading}
              withCloseButton={!deleteCreatorPostMutationState.loading}
              onClose={deleteAlertModal.close}
            >
              <div className={clsx('tw-flex tw-items-center tw-gap-x-4')}>
                <Button
                  theme="outline"
                  size="sm"
                  disabled={deleteCreatorPostMutationState.loading}
                  onClick={deleteAlertModal.close}
                >
                  キャンセル
                </Button>
                <Button
                  theme="negative"
                  size="sm"
                  isLoading={deleteCreatorPostMutationState.loading}
                  onClick={handleDeletePost}
                >
                  削除する
                </Button>
              </div>
            </BaseModal>
          </div>
        </article>
      )}
    </>
  );
};
