import type { IconBaseProps } from '@/components/icons/type';

export const IconLock = ({ boxSize = '1.0em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9974 5.33329H11.3307V3.99996C11.3307 2.15996 9.8374 0.666626 7.9974 0.666626C6.1574 0.666626 4.66406 2.15996 4.66406 3.99996V5.33329H3.9974C3.26406 5.33329 2.66406 5.93329 2.66406 6.66663V13.3333C2.66406 14.0666 3.26406 14.6666 3.9974 14.6666H11.9974C12.7307 14.6666 13.3307 14.0666 13.3307 13.3333V6.66663C13.3307 5.93329 12.7307 5.33329 11.9974 5.33329ZM7.9974 11.3333C7.26406 11.3333 6.66406 10.7333 6.66406 9.99996C6.66406 9.26663 7.26406 8.66663 7.9974 8.66663C8.73073 8.66663 9.33073 9.26663 9.33073 9.99996C9.33073 10.7333 8.73073 11.3333 7.9974 11.3333ZM10.0641 5.33329H5.93073V3.99996C5.93073 2.85996 6.8574 1.93329 7.9974 1.93329C9.1374 1.93329 10.0641 2.85996 10.0641 3.99996V5.33329Z"
        fill="currentColor"
      />
    </svg>
  );
};
