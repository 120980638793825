import { useMemo } from 'react';

import type { CreatorPostCardProps } from './type';

import { linkifyCreatorPostText } from '@/utils/features/creatorPost';

import { Balloon } from './Balloon';
import { Card } from './Card';

export const CreatorPostCard = ({
  theme = 'card',
  ...props
}: CreatorPostCardProps) => {
  const parsedText = useMemo(() => {
    if (!props.creatorPost.text) return;
    return linkifyCreatorPostText(props.creatorPost.text);
  }, [props.creatorPost.text]);

  return theme === 'balloon' ? (
    <Balloon {...props} parsedText={parsedText} />
  ) : (
    <Card {...props} parsedText={parsedText} />
  );
};
