import { clsx } from 'clsx';
import { useReducer, useCallback } from 'react';
import LinesEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

import type { TextEllipsisProps } from './type';

import { useSanitizedHtml } from '@/hooks/utils/useSanitizedHtml';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export const TextEllipsis = ({
  maxLine,
  text,
  className,
  ellipsisText,
  footer,
}: TextEllipsisProps) => {
  const [isClamped, declamp] = useReducer(() => false, true);

  const sanitizedText = useSanitizedHtml(text);

  const ellipsosRef = useCallback(
    (node: (HTMLDivElement & { clamped: boolean }) | null) => {
      if (node !== null && !node.clamped) {
        declamp();
      }
    },
    []
  );

  if (!sanitizedText) return null;

  return isClamped ? (
    <div
      onClick={(event) => {
        event.stopPropagation();
        declamp();
      }}
      tabIndex={0}
      className={clsx('tw-group')}
    >
      <ResponsiveEllipsis
        innerRef={ellipsosRef}
        unsafeHTML={sanitizedText}
        maxLine={maxLine}
        ellipsis={ellipsisText}
        trimRight
        basedOn="letters"
        className={clsx('tw-cursor-pointer', className)}
      />
      {footer !== undefined && isClamped && footer}
    </div>
  ) : (
    <p
      className={className}
      dangerouslySetInnerHTML={{
        __html: sanitizedText,
      }}
    />
  );
};
