import { Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { Fragment, useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import type { DropDownProps } from './type';

import { useDisclosure } from '@/hooks/utils';

export const DropDown = ({
  trigger,
  isDisable = false,
  children,
}: DropDownProps) => {
  const { isOpen, close, toggle } = useDisclosure();

  const containerRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(containerRef, close);

  return (
    <div ref={containerRef} className={clsx('tw-relative tw-inline-block')}>
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
          toggle();
        }}
        disabled={isDisable}
        className={clsx(isDisable && 'tw-cursor-not-allowed')}
      >
        {trigger}
      </button>
      <Transition
        as={Fragment}
        show={isOpen}
        enter={clsx('tw-transition tw-ease-out tw-duration-100')}
        enterFrom={clsx('tw-transform tw-opacity-0 tw-scale-95')}
        enterTo={clsx('tw-transform tw-opacity-100 tw-scale-100')}
        leave={clsx('tw-transition tw-ease-in tw-duration-75')}
        leaveFrom={clsx('tw-transform tw-opacity-100 tw-scale-100')}
        leaveTo={clsx('tw-transform tw-opacity-0 tw-scale-95')}
      >
        <div
          className={clsx(
            'tw-absolute tw-top-0 tw-right-0 tw-z-dropdown',
            'tw-min-w-max tw-py-2 tw-bg-white tw-rounded-[8px] tw-shadow-[0px_2px_12px_rgba(0,0,0,0.1)]'
          )}
        >
          {children}
        </div>
      </Transition>
    </div>
  );
};
