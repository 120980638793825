import { clsx } from 'clsx';
import { filter } from 'graphql-anywhere';

import type { CreatorPostLikedCreatorsModalProps } from './type';

import { CreatorCardHeader } from '@/components/features/creator';
import { IconLoader } from '@/components/icons';
import { BaseModal } from '@/components/ui';
import { CreatorCardHeaderCreatorsFragmentDoc } from '@/graphql/generated';
import { CreatorPostLikedCreatorsModalDocument } from '@/graphql/generated.creator-anonymous';
import { useUser } from '@/hooks/features/auth';
import { useQueryWrapper } from '@/libs/apollo';

export const CreatorPostLikedCreatorsModal = ({
  isOpen,
  postId,
  onClose,
}: CreatorPostLikedCreatorsModalProps) => {
  const { user, isLoading: isLoadingUser, isLoggedIn } = useUser();

  const postLikeCreatorQuery = useQueryWrapper(
    CreatorPostLikedCreatorsModalDocument,
    {
      fetchPolicy: 'network-only',
      role: 'creator-anonymous',
      skip: !isOpen || !postId,
      variables: { postId: postId ?? -1 },
    }
  );

  const data = postLikeCreatorQuery.data?.creatorPostsByPk;

  const count = postLikeCreatorQuery.data?.creatorPostsByPk?.likes.length ?? 0;

  const handleSuccessFollow = (creatorId: number) => {
    postLikeCreatorQuery.updateQuery((prevResult) => {
      if (prevResult.creatorPostsByPk == null) return prevResult;
      return {
        creatorPostsByPk: {
          likes: prevResult.creatorPostsByPk?.likes.map((like) => {
            if (like.creator?.id !== creatorId) return like;
            return {
              ...like,
              creator: {
                ...like.creator,
                followers: [
                  ...like.creator.followers,
                  { __typename: 'CreatorFollowers', id: user?.creator?.id },
                ],
              },
            };
          }),
        },
      };
    });
  };

  const handleSuccessUnfollow = (creatorId: number) => {
    postLikeCreatorQuery.updateQuery((prevResult) => {
      if (prevResult.creatorPostsByPk == null) return prevResult;
      return {
        ...prevResult,
        creatorPostsByPk: {
          ...prevResult.creatorPostsByPk,
          likes: prevResult.creatorPostsByPk.likes.map((like) => {
            if (like.creator?.id !== creatorId) return like;
            return {
              ...like,
              creator: {
                ...like.creator,
                followers: like.creator.followers.filter(
                  (follower) => follower.id !== user?.creator?.id
                ),
              },
            };
          }),
        },
      };
    });
  };

  return (
    <BaseModal
      isOpen={isOpen}
      ariaLabel="いいねしたユーザー"
      title={`いいねしたユーザー（${count}）`}
      withCloseButton
      maxWidthPx={552}
      onClose={onClose}
    >
      <ul className={clsx('tw-space-y-[18px]')}>
        {!isLoadingUser &&
          data &&
          data.likes.map(({ creator }) => {
            if (creator == null) return null;
            return (
              <li key={creator.id ?? -1}>
                <CreatorCardHeader
                  myCreatorId={user?.creator?.id ?? -1}
                  creator={filter(
                    CreatorCardHeaderCreatorsFragmentDoc,
                    creator
                  )}
                  isLoggedIn={isLoggedIn}
                  onSuccessFollow={handleSuccessFollow}
                  onSuccessUnfollow={handleSuccessUnfollow}
                />
              </li>
            );
          })}
        {(isLoadingUser || postLikeCreatorQuery.loading) && (
          <li
            className={clsx(
              'tw-py-3 tw-flex tw-items-center tw-justify-center'
            )}
          >
            <IconLoader boxSize="40px" />
          </li>
        )}
      </ul>
    </BaseModal>
  );
};
