import { clsx } from 'clsx';
import { filter } from 'graphql-anywhere';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import type { CardProps } from './type';
import type { MouseEventHandler } from 'react';

import { CreatorPostFormModal } from '@/components/features/creatorPost/CreatorPostFormModal';
import { CreatorPostLikedCreatorsModal } from '@/components/features/creatorPost/CreatorPostLikedCreatorsModal';
import {
  IconDefaultProfile,
  IconDelete,
  IconDots,
  IconLock,
} from '@/components/icons';
import {
  AnchorLink,
  BaseModal,
  Button,
  DropDown,
  HeadlessButton,
  Image,
  TextEllipsis,
  TextLink,
} from '@/components/ui';
import { CONTENT_WIDTH, DATE_FORMAT, ROUTE } from '@/constants';
import { CreatorPostFormModalCreatorPostsFragmentDoc } from '@/graphql/generated';
import { CreatorPostLikeButtonCreatorPostsFragmentDoc } from '@/graphql/generated.creator';
import { CreatorPostCardDeleteCreatorPostDocument } from '@/graphql/generated.creator-anonymous';
import { useDisclosure } from '@/hooks/utils';
import { useMutationWrapper } from '@/libs/apollo';
import { formatDate } from '@/utils/date';
import { formatCountNumber } from '@/utils/ui';

import { TEXT_MAX_LINES } from '../const';

export const Card = ({
  creatorPost,
  parsedText,
  isSelf,
  withPadding = true,
  canMove = true,
  isEditable,
  readOnly,
  onSuccessEdit,
  onSuccessUpdateLike,
  onSuccessDelete,
}: CardProps) => {
  const router = useRouter();
  const editModal = useDisclosure();
  const deleteAlertModal = useDisclosure();
  const likeCreatorModal = useDisclosure();

  const CreatorPostLikeButton = dynamic(() =>
    import('@/components/features/creatorPost').then(
      (module) => module.CreatorPostLikeButton
    )
  );

  const moveToDetail: MouseEventHandler<HTMLElement> = (_) => {
    if (!canMove) return;
    const detailId = creatorPost.parentPost
      ? creatorPost.parentPost.id
      : creatorPost.id;

    router.push(ROUTE.tsukuLogDetail(detailId));
  };

  const [deleteCreatorPostMutation, deleteCreatorPostMutationState] =
    useMutationWrapper(CreatorPostCardDeleteCreatorPostDocument);

  const handleDeletePost = () => {
    deleteCreatorPostMutation({
      variables: {
        creatorPostId: creatorPost.id,
      },
      onCompleted: ({ deleteCreatorPost }) => {
        onSuccessDelete(deleteCreatorPost.postId);
        deleteAlertModal.close();
      },
    });
  };

  return (
    <>
      {!creatorPost.creator || creatorPost.isDeleted ? (
        <section
          className={clsx(
            'tw-py-6 tw-px-10',
            'tw-flex tw-gap-x-2 tw-items-center'
          )}
        >
          <div
            className={clsx(
              'tw-flex tw-justify-center tw-items-center',
              'tw-size-6'
            )}
          >
            <IconDelete />
          </div>
          <span className={clsx('tw-font-bold tw-text-gray-700')}>
            このつくログは削除されました
          </span>
        </section>
      ) : (
        <article
          className={clsx(
            'tw-py-4',
            withPadding && 'tw-px-spContentSide md:tw-px-10',
            canMove &&
              'tw-cursor-pointer hover:tw-bg-gray-50 tw-transition tw-duration-200'
          )}
          onClick={(event) => moveToDetail(event)}
        >
          {creatorPost.parentPost && (
            <section className={clsx('tw-mb-4')}>
              <div className={clsx('tw-flex tw-items-center tw-gap-x-1')}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.2038 6.17992V3.33337L13.3066 7.83644L8.2038 12.6667C8.18476 11.41 8.2038 9.89386 8.2038 9.89386C6.63997 9.33337 4.30664 9.66671 2.71224 12.3334C2.3612 9.24169 4.02801 6.05259 8.2038 6.1704V6.17992Z"
                    fill="#606971"
                    stroke="#606971"
                    stroke-width="1.1617"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {creatorPost.parentPost.creator &&
                !creatorPost.parentPost.isDeleted ? (
                  <>
                    <div
                      className={clsx(
                        'tw-w-5 tw-h-5 tw-rounded-circle tw-overflow-hidden'
                      )}
                    >
                      {creatorPost.parentPost?.creator.profileImage?.url ? (
                        <Image
                          src={
                            creatorPost.parentPost?.creator.profileImage?.url
                          }
                          alt=""
                          size={{
                            sp: '20px',
                            tablet: '20px',
                            pc: '20px',
                          }}
                        />
                      ) : (
                        <IconDefaultProfile boxSize="20px" />
                      )}
                    </div>
                    <div
                      className={clsx(
                        'tw-max-w-[240px] md:tw-max-w-[356px]',
                        'tw-text-sns14 tw-line-clamp-1'
                      )}
                    >
                      {creatorPost.parentPost?.creator.nickname}
                    </div>
                  </>
                ) : (
                  '削除されたつくログ'
                )}
                <div className={clsx('tw-w-[5em]')}>への返信</div>
              </div>
            </section>
          )}
          {creatorPost.creator && (
            <section
              className={clsx('tw-flex tw-items-center tw-justify-between')}
            >
              <AnchorLink
                href={ROUTE.profile(creatorPost.creator.id)}
                onClick={(event) => event.stopPropagation()}
              >
                <div className={clsx('tw-flex tw-items-center tw-gap-x-2')}>
                  <div
                    className={clsx(
                      'tw-w-10 tw-h-10 tw-rounded-circle tw-overflow-hidden'
                    )}
                  >
                    {creatorPost.creator.profileImage?.url ? (
                      <Image
                        src={creatorPost.creator.profileImage?.url}
                        alt=""
                        objectFit="cover"
                        size={{ sp: '40px', tablet: '40px', pc: '40px' }}
                      />
                    ) : (
                      <IconDefaultProfile boxSize="40px" />
                    )}
                  </div>
                  <div
                    className={clsx(
                      'tw-flex-1 tw-flex tw-flex-col tw-gap-y-0.5'
                    )}
                  >
                    <span
                      className={clsx(
                        'tw-text-ui14 tw-font-bold tw-line-clamp-1'
                      )}
                    >
                      {creatorPost.creator.nickname}
                    </span>

                    <div className="tw-flex tw-items-center tw-gap-x-2">
                      <time
                        dateTime={creatorPost.publishedAt}
                        className={clsx('tw-text-ui10 tw-text-gray-600')}
                      >
                        {formatDate(
                          creatorPost.publishedAt,
                          DATE_FORMAT.yyyyMMddHHmm
                        )}
                      </time>

                      {creatorPost.isPrivate && (
                        <div
                          className={clsx(
                            'tw-flex tw-items-center tw-gap-x-0.5 tw-text-gray-600'
                          )}
                        >
                          <IconLock boxSize="16px" ariaLabel="公開範囲" />
                          <span className={clsx('tw-text-ui10')}>自分のみ</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </AnchorLink>
              {!readOnly && isSelf && (
                <DropDown trigger={<IconDots />} isDisable={!isEditable}>
                  <ul>
                    <li
                      className={clsx(
                        'tw-pt-2 tw-pb-3 tw-px-4',
                        'tw-border-b tw-border-gray-200'
                      )}
                    >
                      <button
                        type="button"
                        className={clsx(
                          'tw-text-ui16',
                          'hover:tw-opacity-75 tw-transition tw-duration-200'
                        )}
                        onClick={(event) => {
                          event.stopPropagation();
                          editModal.open();
                        }}
                      >
                        {`${creatorPost.parentPost ? '返信' : '投稿'}を編集`}
                      </button>
                    </li>
                    <li className={clsx('tw-pt-2 tw-pb-3 tw-px-4')}>
                      <button
                        type="button"
                        className={clsx(
                          'tw-text-ui16 tw-text-error',
                          'hover:tw-opacity-75 tw-transition tw-duration-200'
                        )}
                        onClick={(event) => {
                          event.stopPropagation();
                          deleteAlertModal.open();
                        }}
                      >
                        {`${creatorPost.parentPost ? '返信' : '投稿'}を削除`}
                      </button>
                    </li>
                  </ul>
                </DropDown>
              )}
            </section>
          )}
          {parsedText != null && (
            <section className={clsx('tw-mt-2')}>
              <TextEllipsis
                text={parsedText}
                maxLine={TEXT_MAX_LINES}
                ellipsisText="..."
                className={clsx(
                  'tw-text-sns14 tw-whitespace-break-spaces tw-break-all'
                )}
                footer={
                  <button
                    className={clsx(
                      'tw-w-full tw-pt-3.5 tw-text-sns14 tw-text-gray-700 tw-text-left',
                      'group-hover:tw-opacity-75 tw-transition tw-duration-200'
                    )}
                  >
                    続きを読む
                  </button>
                }
              />
            </section>
          )}

          {creatorPost.image != null && (
            <section className={clsx('tw-mt-4')}>
              <a
                href={creatorPost.image.url}
                target="_blank"
                rel="noreferrer"
                className={clsx(
                  'tw-block tw-w-fit tw-max-w-full tw-max-h-48 tw-rounded-lg tw-overflow-hidden',
                  'md:tw-max-h-64'
                )}
                onClick={(event) => event.stopPropagation()}
              >
                <Image
                  src={creatorPost.image.url}
                  alt=""
                  size={{
                    sp: `${CONTENT_WIDTH.sp}px`,
                    tablet: `${CONTENT_WIDTH.tablet / 2}px`,
                    pc: `${CONTENT_WIDTH.pc / 2}px`,
                  }}
                />
              </a>
            </section>
          )}

          {!readOnly && (
            <section
              className={clsx(
                'tw-mt-4 tw-pt-2',
                'tw-flex tw-items-center tw-justify-between'
              )}
            >
              <div
                className={clsx('tw-flex tw-gap-x-4 tw-relative tw-z-docked')}
              >
                {!creatorPost.parentPost && (
                  <TextLink
                    href={ROUTE.tsukuLogDetail(creatorPost.id)}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <span className={clsx('tw-text-ui12 tw-text-gray-600')}>
                      {formatCountNumber(
                        creatorPost.childPostsAggregate.aggregate?.count ?? 0,
                        0,
                        9999
                      )}
                      件の返信
                    </span>
                  </TextLink>
                )}
                <HeadlessButton
                  onClick={(event) => {
                    event.stopPropagation();
                    likeCreatorModal.open();
                  }}
                >
                  <span className={clsx('tw-text-ui12 tw-text-gray-600')}>
                    {formatCountNumber(creatorPost.likeTotal, 0, 9999)}
                    件のいいね
                  </span>
                </HeadlessButton>
              </div>
              <CreatorPostLikeButton
                creatorPost={filter(
                  CreatorPostLikeButtonCreatorPostsFragmentDoc,
                  creatorPost
                )}
                onSuccessUpdateLike={onSuccessUpdateLike}
              />
            </section>
          )}
        </article>
      )}
      <CreatorPostLikedCreatorsModal
        isOpen={likeCreatorModal.isOpen}
        postId={creatorPost.id}
        onClose={likeCreatorModal.close}
      />

      <CreatorPostFormModal
        isOpen={editModal.isOpen}
        creatorPost={filter(
          CreatorPostFormModalCreatorPostsFragmentDoc,
          creatorPost
        )}
        onSuccessUpsert={onSuccessEdit}
        onClose={editModal.close}
      />
      <BaseModal
        isOpen={deleteAlertModal.isOpen}
        title={`${creatorPost.parentPost ? '返信' : '投稿'}を削除しますか？`}
        ariaLabel={`${creatorPost.parentPost ? '返信' : '投稿'}削除警告`}
        closeOnOverlayClick={!deleteCreatorPostMutationState.loading}
        withCloseButton={!deleteCreatorPostMutationState.loading}
        onClose={deleteAlertModal.close}
      >
        <div className={clsx('tw-flex tw-items-center tw-gap-x-4')}>
          <Button
            theme="outline"
            size="sm"
            disabled={deleteCreatorPostMutationState.loading}
            onClick={deleteAlertModal.close}
          >
            キャンセル
          </Button>
          <Button
            theme="negative"
            size="sm"
            isLoading={deleteCreatorPostMutationState.loading}
            onClick={handleDeletePost}
          >
            削除する
          </Button>
        </div>
      </BaseModal>
    </>
  );
};
