import type { IconBaseProps } from '@/components/icons/type';

export const IconDots = ({ boxSize = '1.5em' }: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="12" cy="12" r="2" fill="#76828F" />
        <circle cx="6" cy="12" r="2" fill="#76828F" />
        <circle cx="18" cy="12" r="2" fill="#76828F" />
      </g>
    </svg>
  );
};
